table {
	border-collapse: collapse;
	height: min-content;
	width: 100%;
	/* default full width */
}

th,
td {
	padding: 8px;
	text-align: center;
	border: 1px solid #dddddda3;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: #00000000;
}

::-webkit-scrollbar-thumb {
	background: #3f3f3f;
}

::-webkit-scrollbar-thumb:hover {
	background: #8d8d8d;
}