@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
	font-family: 'Pretendard Variable', sans-serif;
	background-color: #020202;
}

::-moz-selection {
	color: white;
	background: black;
}

::selection {
	color: white;
	background: black;
}

@layer components {
	.text-contents {
		@apply backdrop-blur-2xl p-4 backdrop-brightness-110 dark:backdrop-brightness-75 backdrop-hue-rotate-[1deg] shadow-sm bg-gradient-to-br from-transparent to-neutral-100 dark:to-[#0e0e0e44] dark:shadow-[#0e0e0e44] leading-relaxed
	}

	.tag {
		@apply p-1 px-2 whitespace-nowrap backdrop-blur-xl transition-all ease-in-out backdrop-brightness-110 bg-neutral-50 dark:bg-black bg-opacity-30 dark:bg-opacity-30 hover:text-neutral-500 shadow-sm font-semibold
	}

	.tag-lg {
		@apply p-1 px-2 whitespace-nowrap backdrop-blur-xl transition-all ease-in-out backdrop-brightness-110 bg-neutral-50 dark:bg-black bg-opacity-30 dark:bg-opacity-30 hover:text-neutral-500 shadow-sm font-semibold text-xs
	}

	.tag-static {
		@apply p-1 px-2 whitespace-nowrap backdrop-blur-xl transition-all ease-in-out backdrop-brightness-110 bg-neutral-50 dark:bg-black bg-opacity-30 dark:bg-opacity-30 shadow-sm font-semibold
	}

	.tag-selected {
		@apply bg-opacity-100 dark:bg-opacity-100 underline shadow-sm font-semibold
	}

	label {
		@apply text-base font-bold
	}

	input {
		@apply w-full p-2 px-4 backdrop-blur-xl backdrop-brightness-110 dark:backdrop-brightness-75 bg-neutral-50 dark:bg-neutral-600 bg-opacity-30 dark:bg-opacity-30 shadow-sm
	}

	textarea {
		@apply w-full p-2 px-4 backdrop-blur-xl backdrop-brightness-110 dark:backdrop-brightness-75 bg-neutral-50 dark:bg-neutral-600 bg-opacity-50 dark:bg-opacity-50 shadow-sm
	}

	.submit-button {
		@apply p-2 px-4 backdrop-blur-xl backdrop-brightness-110 dark:backdrop-brightness-75 bg-neutral-50 dark:bg-neutral-600 bg-opacity-30 dark:bg-opacity-30 shadow-sm transition-all ease-in-out hover:text-neutral-500 hover:bg-neutral-700
	}

	.button-red {
		@apply p-2 px-4 backdrop-blur-xl backdrop-brightness-110 dark:backdrop-brightness-75 bg-red-300 dark:bg-red-900 bg-opacity-30 dark:bg-opacity-30 shadow-sm transition-all ease-in-out hover:text-neutral-500 hover:bg-red-700
	}
}